import Cookies from 'js-cookie';
import consentRequest, { ICustomerConsentResponse } from 'components/Gdpr/requests/consentRequest';
import { useRequest } from 'redux-query-react';
import { useSelector } from 'react-redux';
import { CookieType } from 'components/Gdpr/enums/CookieType';

const mapStateToProps = (state) => {
    return {
        customerConsent: state.entities.customerConsentRequest as ICustomerConsentResponse,
    };
};

export default function useGdprConsent() {
    const gdprCookieToken = Cookies.get('gdpr_cookie_token') ?? '';
    const [{ isFinished, status }] = useRequest(consentRequest(gdprCookieToken));
    const { customerConsent } = useSelector(mapStateToProps);

    const hasConsent = (cookieCode: CookieType) => {
        const consent = customerConsent?.data.find((consent) => consent.code === cookieCode);

        return consent?.status;
    };

    return {
        isFinished,
        status,
        customerConsent,
        hasConsent,
    };
}
