import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useRequest } from 'redux-query-react';
import { useRef, useState } from 'react';

import { request } from 'data/requests/request';

import FrameCheckout from 'andbeauty-ui/lib/Frames/Checkout';
import LayoutSidebar from 'andbeauty-ui/lib/Layouts/LayoutSidebar';
import LayoutColumnPrimary from 'andbeauty-ui/lib/Layouts/LayoutSidebarPrimary';
import LayoutColumnSecondary from 'andbeauty-ui/lib/Layouts/LayoutSidebarSecondary';
import Block from 'andbeauty-ui/lib/Components/Block';
import { useEffect } from 'react';
import MessageWidget from 'components/message/Widget';
import FramePage from 'components/frames/FramePage';
import RedirectAbsolute from 'components/Redirect/RedirectAbsolute';
import ShippingContent from 'components/Checkout/Shipping/ShippingContent';
import CartOverlay from 'components/Checkout/Cart/CartOverlay';
import { ResponseStatus } from '../../enums/ResponseStatus';
import isLoggedIn from '../../helpers/auth/isLoggedIn';
import { IQuote } from 'components/Checkout/interfaces/checkout/quote/IQuote';
import Cart from 'components/Checkout/Cart/Cart';
import useOverlays from 'components/overlay/Overlay';
import { ICart } from 'components/Checkout/interfaces/checkout/cart/cartType';
import GtagEvents from 'components/SeoSuite/GtagEvents';

const mapStateToProps = (state) => {
    return {
        quote: state.entities.quote,
        quoteId: state.entities.quote?.id,
        customer: state.entities.quote?.customer,
    };
};

const FrameMain = () => {
    const { openOverlay } = useOverlays();

    const quoteRequest = useRequest<IQuote | null>(
        request({ type: 'quote', url: isLoggedIn() ? 'carts/mine' : `guest-carts/${window.quoteIdMask}` }),
    );
    const history = useHistory();
    const { t } = useTranslation();
    const { customer, quote } = useSelector(mapStateToProps);
    const [cart, setCart] = useState<ICart | undefined>();
    const shippingPrice = useRef(0.0);
    const [updateState, setUpdateState] = useState(false);

    const CartContent = (
        <Cart customer={customer} cart={cart} quote={quote} setCart={setCart} shippingPrice={shippingPrice} />
    );
    const updateShipping = (event) => {
        shippingPrice.current = parseFloat(event.detail?.discountPrice ?? event.detail?.price);
        setUpdateState(!updateState);
    };

    useEffect(() => {
        window.addEventListener('shipping-additional-change', updateShipping);
        return function cleanup() {
            window.removeEventListener('shipping-additional-change', updateShipping);
        };
    });
    const quoteRequestFinished = quoteRequest && quoteRequest[0].isFinished;
    if (quoteRequestFinished && (quoteRequest[0].status !== ResponseStatus.ok || (quote && quote.items_qty < 1))) {
        return <RedirectAbsolute to={'checkout'} />;
    }

    return (
        <React.Fragment>
            {quoteRequestFinished && (
                <FrameCheckout
                    title={t('checkout.Checkout')}
                    login={
                        window.isLoginEnabled && !customer?.firstname
                            ? {
                                  title: t('checkout.Sign In'),
                                  href: `${window.LOGIN_URL}`,
                              }
                            : undefined
                    }
                    logoHref={window.API_URL}
                    logoURL={window.checkoutConfig?.logo_src}
                    wrapHeader={true}
                >
                    <React.Fragment>
                        <FramePage title={t('checkout.Checkout')}>
                            <CartOverlay CartContent={CartContent} />
                            <Block>
                                <MessageWidget />
                                <LayoutSidebar
                                    openSidebarLabel={t('checkout.View purchase summary')}
                                    openSidebar={(e) => {
                                        e.preventDefault();
                                        history.push({ hash: 'summary' });
                                        openOverlay('summary', true);
                                    }}
                                    openSidebarIcon="cart"
                                    openSidebarAddOn={`${cart ? cart.base_grand_total : 0} €`}
                                >
                                    <LayoutColumnPrimary title={t('Checkout')} icon="checklist">
                                        <ShippingContent quote={quote} cart={cart} />
                                    </LayoutColumnPrimary>
                                    <LayoutColumnSecondary title={t('checkout.Order Summary')} icon="cart">
                                        {CartContent}
                                    </LayoutColumnSecondary>
                                </LayoutSidebar>
                            </Block>
                            <GtagEvents />
                        </FramePage>
                    </React.Fragment>
                </FrameCheckout>
            )}
        </React.Fragment>
    );
};

export default FrameMain;
