import { request } from 'data/requests/request';
import { CookieType } from 'components/Gdpr/enums/CookieType';

const consentRequest = (gdprCookieToken: string) =>
    request({
        type: 'customerConsentRequest',
        url: `rpdg/cookieconsent/load?gdprCookieToken=${gdprCookieToken}`,
        method: 'GET',
        notApi: true,
        absolute: false,
        force: false,
    });

export default consentRequest;

export interface ICustomerConsentResponse {
    errors: boolean;
    data: Array<{
        code: CookieType;
        status: boolean;
    }>;
}
