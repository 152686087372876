import { useEffect } from 'react';
import { CookieType } from 'components/Gdpr/enums/CookieType';
import useGdprConsent from '../../hooks/useGdprConsent';

export default function ConsentHandler() {
    const { isFinished, status, customerConsent, hasConsent } = useGdprConsent();

    useEffect(() => {
        if (isFinished && status === 200 && !customerConsent.errors) {
            const gdprScriptNodes = document.querySelectorAll('script[type="text/x-magento-gdpr"]');

            gdprScriptNodes.forEach((scriptElement) => {
                const cookieCode = scriptElement.getAttribute('data-consent');

                if (hasConsent(cookieCode as CookieType)) {
                    const approvedScriptElement = document.createElement('script');
                    approvedScriptElement.setAttribute('type', 'text/javascript');
                    approvedScriptElement.innerHTML = scriptElement.innerHTML;

                    scriptElement.after(approvedScriptElement);

                    if (scriptElement.parentElement) {
                        scriptElement.parentElement.removeChild(scriptElement);
                    }
                }
            });
        }
    }, [isFinished, status]);

    return null;
}
